.contact {
  padding: 60px 0px;
}

.contact__title {
  font-size: 60px;
  text-align: center;
  font-family: "Abril Fatface" serif;
}

.contact__images-wrapper {
  position: relative;
}

.contact__images-wrapper::before {
  content: "";
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 10px solid #6a8229;
  background-color: white;
  position: absolute;
  left: 30%;
}

.contact__images-wrapper::after {
  content: "";
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 10px solid #6a8229;
  background-color: white;
  position: absolute;
  right: 33%;
  bottom: 120px;
}

.contact__image-1 {
  display: block;
  width: 300px;
  height: 300px;
  background-image: url("../../Images/alimond.jpg");
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  margin: 30px auto;
  position: relative;
  left: 22px;
}

.contact__image-2 {
  display: block;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-image: url("../../Images/fruits.jpeg");
  background-position: center;
  background-size: cover;
  position: relative;
  left: 32%;
  top: -155px;
  border: 10px solid #6a8229;
}

.contact__form {
  margin-top: -32px;
}

.contact__input__wrapper {
  display: flex;
  justify-content: center;
  column-gap: 80px;
}

.contact__input {
  display: block;
  margin-top: 2px;
  width: 250px;
  padding: 4px 0px 4px 10px;
  border-radius: 20px;
}

.contact__btn {
  display: block;
  background-color: #6a8229;
  margin: 42px auto 0px;
  padding: 2px 80px;
  border-radius: 25px;
  border: none;
  font-size: 25px;
  font-family: "Abril Fatface" serif;
  font-weight: 700;
}

.contact__label {
  font-size: 16px;
  font-weight: 700;
  text-align: left;
}

.contact__address {
  margin-top: 65px;
  display: flex;
  justify-content: center;
  column-gap: 90px;
}

.contact__address-title {
  font-size: 24px;
  font-weight: 700;
}

.contact__address-text {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
}

.contact__location {
  display: inline-block;
  margin-top: -30px;
}

.contact__address__button {
  display: block;
  margin: 10px auto 0px;
  background-color: #6a8229;
  border-radius: 20px;
  padding: 2px 30px;
  border: none;
}

.contact__address-text {
  display: inline-block;
  margin: 5px 10px;
}

@media screen and (max-width: 992px) {
  .contact__address {
    column-gap: 30px;
  }
}

@media screen and (max-width: 768px) {
  .contact__address {
    max-width: 300px;
    margin: 50px auto 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 30px;
  }
}

@media screen and (max-width: 576px) {
  .contact {
    padding: 30px 0px;
  }
  
  .contact__input__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .contact__images-wrapper::before {
    content: "";
    display: none;
  }

  .contact__images-wrapper::after {
    content: "";
    display: none;
  }

  .contact__image-2 {
    left: 10%;
  }
}
