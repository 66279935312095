.footer {
  background-color: #6a8229;
  margin-top: 50px;
}

.footer__wrapper {
  padding: 1rem 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
}

@media screen and (max-width: 576px) {
  .footer__wrapper {
    flex-direction: column;
    align-items: center;
  }
}
