* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: "Abril Fatface", serif;
}

 
@media screen and (min-width: 863px) {
   
.img {
    position: fixed;
    bottom: 98px;
    right: 98px;
    height: 70px;
    width: 70px;
    transition: transform 0.5s ease;
    z-index: 98;
}

.img-contact {
    position: fixed;
    bottom: 90px;
    right: 90px;
    height: 90px;
    width: 90px;
    background-color: rgb(157, 48, 9);
    border-radius: 50%;
    z-index: 99;
}


.img-contact-phone {
    transform: translateX(-0px) translateY(-230px);
}

.img-contact-instagram {
    transform: translateX(-90px) translateY(-212px);
}

.img-contact-whatsapp {
    transform: translateX(-172px) translateY(-162px);
}

.img-contact-telegram {
    transform: translateX(-240px) translateY(-80px);
}

.img-contact-email {
    transform: translateX(-270px) translateY(-0px);
} 
}

@media screen and (max-width: 863px) {
   
    .img {
        position: fixed;
        bottom: 55px;
        right: 55px;
        height: 70px;
        width: 70px;
        transition: transform 0.5s ease;
        z-index: 98;
    }
    
    .img-contact {
        position: fixed;
        bottom: 50px;
        right: 50px;
        height: 90px;
        width: 90px;
        background-color: rgb(157, 48, 9);
        border-radius: 50%;
        z-index: 99;
    }
    
    
    .img-contact-phone {
        transform: translateX(-0px) translateY(-550px);
    }
    
    .img-contact-instagram {
        transform: translateX(-0px) translateY(-440px);
    }
    
    .img-contact-whatsapp {
        transform: translateX(-0px) translateY(-330px);
    }
    
    .img-contact-telegram {
        transform: translateX(-0px) translateY(-220px);
    }
    
    .img-contact-email {
        transform: translateX(-0px) translateY(-110px);
    } 
    }
    