.products {
  padding: 77px 0px 154px;
  background-image: url("../../Images/fruitsBack1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.products__main__title {
  font-size: 56px;
  color: white;
  text-align: center;
  margin-bottom: 60px;
}

.products__list {
  display: flex;
  justify-content: center;
  gap: 100px;
  margin: 0;
  padding: 0;
}

.products__item {
  list-style: none;
  background-color: white;
  padding: 25px 70px 140px 20px;
  border-radius: 10px;
}

.products__title {
  font-size: 27px;
  font-weight: 600;
}

.products__item__item {
  font-size: 18px;
  list-style: disc;
}

@media screen and (max-width: 992px) {
  .products__list {
    justify-content: space-between;
    gap: 20px;
  }

  .products__item {
    padding: 25px 50px 140px 20px;
  }
}


@media screen and (max-width: 768px) {
    .products__list {
      justify-content: space-between;
      gap: 20px;
      flex-direction: column;
    
    }
  
    .products__item {
      padding: 25px 50px 140px 20px;
    }
  }
  
  @media screen and (max-width:576px) {
    .products {
        padding: 50px 0px ;
     
      }
      
    
  }