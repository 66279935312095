.intro {
  height: 600px;
  background-image: url("../../Images/fruitsBack.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.intro__content {
  display: inline-block;
  padding: 5px 60px 30px;
  background-color: white;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 50%);
  border-radius: 20px;
  text-align: center;
}

.intro__title {
  font-size: 26px;
  color: #6a8229;
  margin-bottom: 10px;
}

.intro__text {
  max-width: 270px;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
}

.intro__text span {
  text-decoration: underline;
}

.intro__link {
  padding: 10px 20px;
  background-color: #6a8229;
  border-radius: 20px;
  text-decoration: none;
  color: black;
  font-size: 16px;
  font-weight: 700;
}

@media screen and (max-width: 992px) {
  .intro__content {
    padding: 5px 60px 30px;
    bottom: 50%;
  }
}


@media screen and (max-width: 480px) {
    .intro__content {
      padding: 20px 10px ;
    }
  }
  