.productslist {
  padding: 200px 0px 20px;
  background-color: #6a8229;
  border-bottom: 1px solid white;
}

.productslist__items {
  display: flex;
  flex-wrap: nowrap;
  gap: 100px;
}

.productslist__item {
  max-width: 300px;
  padding: 20px 40px;
  background-color: white;
  border-radius: 20px;
  list-style: none;
  position: relative;
  text-align: center;
}

.productslist__item::after {
  content: "";
  display: block;
  width: 100px;
  height: 100px;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%);
  top: 20px;
  border-radius: 50%;
  background-color: #6a8229;
}

.productslist__image {
  display: block;
  max-width: 100px;
  height: 100px;
  position: relative;
  z-index: 10;
  margin-bottom: 10px;
}

.productslist__title {
  font-size: 26px;
  color: #6a8229;
  margin-bottom: 10px;
}

.productslist__text {
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 0px;
}

.productslist__link {
  color: #6a8229;
  font-size: 19px;
  font-weight: 600;
}

@media screen and (max-width: 992px) {
  .productslist {
    padding: 100px 0px 100px;
    background-color: #6a8229;
    border-bottom: 1px solid white;
  }

  .productslist__item {
    padding: 20px;
  }

  .productslist__items {
    gap: 20px;
  }

  .productslist__item::after {
    content: "";
    display: block;
    width: 100px;
    height: 100px;
    position: absolute;
    z-index: 1;
    right: 10%;
    top: 20px;
    border-radius: 50%;
    background-color: #6a8229;
  }
}

@media screen and (max-width: 778px) {
  .productslist {
    padding: 100px 0px 100px;
  }

  .productslist__items {
    padding-left: 0px;
    gap: 20px;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 576px) {
  .productslist {
    padding: 50px 0px 50px;
  }
}
