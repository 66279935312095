.famous-products {
  padding: 30px 60px;
}

.famous-products__main__title {
  font-size: 38px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
}

.famous-products__list {
  display: flex;
  flex-wrap: wrap;
  gap: 80px;
  margin: 0;
  padding: 0;
}

.famous-products__item {
  width: 45%;
  list-style: none;
  display: flex;
  gap: 30px;
}

.famous-products__item-sm {
  flex-direction: row-reverse;
}

.famous-products__image {
  width: 150px ;
  height: 150px;
  border-radius: 50%;
  margin-top: 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}


.famous-products__title {
  font-size: 26px;
  font-weight: 600;
  color: #6a8229;
}

.famous-products__text {
  font-size: 16px;
  font-family: "Arimo" serif;
}

@media screen and (max-width: 992px) {
  .famous-products__item {
    width: 90%;
  }

  .famous-products__item {
    align-items: center;
  }
}

@media screen and (max-width: 778px) {
  .famous-products__item {
    width: 100%;
  }

  .famous-products__list {
    gap: 50px;
  }
}

@media screen and (max-width: 576px) {
  .famous-products__list {
    gap: 30px;
    text-align: center;
  }

  .famous-products__item,
  .famous-products__item-sm {
    flex-direction: column;
  }

  .famous-products__item-sm {
    flex-direction: column-reverse;
  }
}