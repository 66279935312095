.about-us {
  padding: 200px 0px 100px;
  background-color: white;
}

.about-us__left {
  max-width: 500px;
}

.about-us__wrapper {
  display: flex;
  justify-content: space-between;
}

.about-us__main-title {
  font-size: 32px;
  color: #525252;
}

.about-us__title {
  font-size: 56px;
  font-weight: 600;
}

.about-us__text {
  font-size: 23px;
}

.about-us__image {
  margin-top: 75px;
  width: 400px;
  height: 400px;
  position: relative;
  z-index: 10;
  border-radius: 50%;
  border: 30px solid #6a8229;
  background-image: url("../../Images/man.png");
  background-position: left -100px top 0px;
  background-repeat: no-repeat;
  background-size: cover;
}

.about-us__image::before {
  content: "";
  width: 150px;
  height: 150px;
  position: absolute;
  z-index: 1;
  top: -80px;
  left: -80px;
  border-radius: 50%;
  border: 15px solid #6a8229;
}

.about-us__image::after {
  content: "";
  width: 150px;
  height: 150px;
  position: absolute;
  top: 230px;
  left: -70px;
  border-radius: 50%;
  border: 10px solid #d9d9d9;
  background-image: url("../../Images/fruits.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 992px) {
  .about-us {
    padding: 100px 0px 100px;
  }
  .about-us__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .about-us__title {
    font-size: 46px;
    font-weight: 600;
  }

  .about-us__text {
    font-size: 20px;
  }
}

@media screen and (max-width: 576px) {
  .about-us {
    padding: 50px 0px 100px;
  }
  .about-us__main-title {
    font-size: 22px;
    color: #525252;
  }

  .about-us__title {
    font-size: 36px;
    font-weight: 600;
  }

  .about-us__text {
    font-size: 16px;
  }

  .about-us__image {
    margin-top: 50px;
    width: 300px;
    height: 300px;
  }
  .about-us__image::before {
    display: none;
  }

  .about-us__image::after {
    width: 120px;
    height: 120px;
    top: 170px;
    left: -40px;
  }
}
