
.imgIcon{
    width: 90px;
    height: 90px;
}

.nav__select{
    margin-right: 20px;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 1em;
    border: 2px solid black;
}

.navbar-toggler {
border: none !important;
}
@media screen and (max-width:770px){
    .div-number{
        display: none !important;
    }
}
@media screen and (min-width:770px){
    .div-number{
        display: flex !important;
    }
}