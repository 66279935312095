.quality {
  height: 600px;
  padding: 100px 0px 0px;
  background-color: #6a8229;
}

.quality__wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.quality__main__title {
  font-size: 32px;
  margin-bottom: 30px;
  color: white;
  text-align: center;
  max-width: 400px;
}

.quality__left,
.quality__right {
  width: 448px;
  height: auto;
  background-color: #085627;
  padding: 30px 40px;
  border-radius: 20px;
  font-size: 22px;
  align-self: baseline;
  text-align: center;
}

.quality__image {
  max-width: 100%;
  border-radius: 10px;
}

.quality__text {
  margin-top: 35px;
  color: white;
}

.quality__suptext {
  color: #00bf63;
}

@media screen and (max-width: 992px) {
  .quality {
    height: auto;
    padding: 100px 0px 100px;
  }

  .quality__wrapper {
    flex-direction: column-reverse;
    align-items: center;
    gap: 40px;
  }

  .quality__left,
  .quality__right {
    width: 90%;
    margin: auto;
  }
}

@media screen and (max-width: 576px) {
  .quality {
    padding: 50px 0px 50px;
  }
  
.quality__left,
.quality__right {
  width: 100%;
  height: auto;
  background-color: #085627;
  padding: 20px ;

}

}
